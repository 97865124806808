/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import twitter from './assets/x.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import logoPic from './assets/logo.png';
import x from './assets/twitter.png';
import tg from './assets/telegram.png';
import instagram from './assets/instagram.png';
import mail from './assets/email.png';
import copy from './assets/copy.png';
import video from './assets/Under_the_Sea.mp4';
import Footer from './components/footer';
import border from './assets/border.png';
import gem from './assets/gem.png';
import gem2 from './assets/gem2.png';
import phantom from './assets/phantom.png';
import solflare from './assets/solflare.png';

import $ from 'jquery';
import About from './components/about';
import Tokenomics from './components/tokenomics';
import Team from './components/team';
import Intro from './components/intro';
import Token from './components/token';

import axios from 'axios';
import Solflare from '@solflare-wallet/sdk';

/*import {
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
*/

import {
  SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
  LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import {
  transfer, mintTo, getMint, createMint, getOrCreateAssociatedTokenAccount, getAccount,
} from '@solana/spl-token';
import './buffer-polyfill';
import { disconnect } from 'process';



var Scroll = require('react-scroll');

$(window).on('load', function () {
  setTimeout(function () { // allowing 3 secs to fade out loader
    $('.page-loader').fadeOut('slow');
  }, 2000);
});

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  /*	if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {*/
  // Render a countdowns

  if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

    window.location.reload(true);
    console.log("Mint Begins");
  }


  return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
  /*	}*/
};

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const xLink = () => {
  window.open("https://x.com/mememon_io");
}

const insta = () => {
  window.open("https://www.instagram.com/mememon_io?");
}

const teleG = () => {
  window.open("https://t.me/mememon_io");
}

const email = () => {
  window.open("mailto:support@mememon.io");
}

const os = () => {
  window.open("https://opensea.io/collection/lola-s-dinodreams-a-magical-journey-with-lemon");
}


const Home = () => {

  const [_navbarOpen, set_navbarOpen] = useState(0);
  const [_presaleOpen, set_presaleOpen] = useState(0);
  const [walletObject, setwalletObject] = useState(null);
  //const [solflareWalletObject, setSolflareWalletObject] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [myWalletbalance, setMywalletBalance] = useState(0);
  const [toTokenAccount, setToTokenAccount] = useState(null);
  const [keyPair, setKeyPair] = useState(null);
  const [_tokenAmount, set_tokenAmount] = useState('');
  const [response, setResponse] = useState('');
  const [_walletDiv, set_walletDiv] = useState(0);
  const [_walletType, setWalletType] = useState();

  const walletSolflare = new Solflare();
  //const walletPhantom = new PhantomWalletAdapter();


  const solfareConnect = async () => {


   /* try {
     await walletSolflare.connect();
      //await walletPhantom.connect();
      console.log('connectedSOLFLARE--1', walletSolflare.publicKey.toString());
      // console.log('connectedSOLFLARE--1', walletPhantom.publicKey.toString());
    } catch (err) {
      console.log(err);
    }*/

    await window.solflare.connect();
    await window.solflare.on("connect", () => console.log("connected!"));
    setTimeout(() => {
      const wallet = window.solflare.publicKey.toString();
      console.log(wallet);
    }, 300);


    setSolflareWalletObject(window.solflare);
    setWalletAddress(window.solflare.publicKey.toString());


    console.log("Connected with public key:", window.solflare.publicKey.toString());

    // checkIfSolfareIsConnected();


    setWalletType("2");

    walletDivClose();



  };


  const _disconnectWallet = async () => {
    try {
      //  await walletObject.disconnect();
      console.log("Disconnected from wallet");
      setWalletAddress(null);
      setwalletObject(null);
      window.location.reload(true);

    } catch {
      console.log(err);
    }
  };

  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;
      console.log("solana :" + solana);
      if (solana) {
        if (solana.isPhantom) {
          console.log("Phantom wallet found!");
          const response = await solana.connect({
            onlyIfTrusted: true,
          });
          console.log("Connected with public key!", response.publicKey.toString());
          setWalletAddress(response.publicKey.toString());

          // Set up wallet object for later use
          setwalletObject(response);
        }

      } else {
        alert("Solana object not found! Get a Phantom wallet")
      }



    } catch (error) {
      console.error(error);
    }
  };
  /*
    const checkIfSolfareIsConnected = async () => {
      try {
  
        const { solana } = window;
        console.log("solana :" + solana.publicKey);
  
        const message = 'Mememon';
  
        const messageBytes = new TextEncoder().encode(message);
        
        const signature = await window.solflare.signMessage(messageBytes, 'utf8');
  
        console.log(signature); 
        
  
  
  
      } catch (error) {
        console.error(error);
      }
    };
  */

  const sendMoney = async () => {

    // console.log("walletObject.publicKey" + walletObject.publicKey);
    // console.log("walletObject:", JSON.stringify(walletObject, null, 2));

    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");

    // Transfer SOL
    const price = 0.00000267 * LAMPORTS_PER_SOL;

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: walletObject.publicKey,
        toPubkey: new PublicKey('8rAzwoJEFcQXiD5PXWJWCBGU2ra2FowbgiCz7P18mk81'),
        lamports: price * Number(_tokenAmount),
      }),
    );


    //Source Account
    transaction.feePayer = walletObject.publicKey;
    transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    let signature;


    signature = await window.solana.signAndSendTransaction(transaction);



    console.log('Transaction sent:', signature);

    if (signature != null) {

      const compileResponse = await axios.post('https://hammerhead-app-bfiby.ondigitalocean.app/api/buy', {

        walletAddress: walletAddress,
        tokenAmount: _tokenAmount,
        _signature: signature

      });

      console.log("response: " + compileResponse.data);

      if (compileResponse.data === 'Tokens Transferred Successfully') {

        console.log("Transferring....");

        setResponse("Tokens Transferred Successfully");

      } else {

        setResponse(`Error during compilation: ${compileResponse.data}`);

      }

      console.log("signature found!");

    }

  }

  const checkSendMoneySolflare = async () => {
    if (solflareWalletObject) {
      sendMoneySolflare();
    } else {
      console.error('Solflare wallet object is null');
    }

  }

  const sendMoneySolflare = async () => {

    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");

    // Transfer SOL
    const price = 0.00000267 * LAMPORTS_PER_SOL;

    const transaction = new Transaction();
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: window.solflare.publicKey,
        toPubkey: new PublicKey('8rAzwoJEFcQXiD5PXWJWCBGU2ra2FowbgiCz7P18mk81'),
        lamports: price * Number(_tokenAmount),
      })
    );

    // Set fee payer and recent blockhash
    transaction.feePayer = window.solflare.publicKey;
    transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    // Sign and send the transaction
    const txSignature = await window.solflare.signAndSendTransaction(transaction);


    console.log('Transaction sent:', txSignature);

    if (txSignature != null) {

      const compileResponse = await axios.post('https://hammerhead-app-bfiby.ondigitalocean.app/api/buy', {

        walletAddress: walletAddress,
        tokenAmount: _tokenAmount,
        _signature: txSignature

      });

      console.log("response: " + compileResponse.data);

      if (compileResponse.data === 'Tokens Transferred Successfully') {

        console.log("Transferring....");

        setResponse("Tokens Transferred Successfully");

      } else {

        setResponse(`Error during compilation: ${compileResponse.data}`);

      }

      console.log("signature found!");

    }

  }

  const signMsg = async () => {

    // Sign a message using the user's wallet
    const message = Uint8Array.from([...new TextEncoder().encode('MemeMon')]);
    const signedMessage = await walletSolflare.signMessage(message);

    // Check if the message property exists before converting it to an array
    let serializedMessage;
    if ('message' in signedMessage) {
      serializedMessage = Array.from(signedMessage.message);
    } else {
      serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
    }

    // Add the signature and serialized message to the response object
    walletSolflare.signature = signedMessage.signature;
    walletSolflare.serializedMessage = serializedMessage;

    console.log("Connected with public key:", walletObject.publicKey.toString());
    console.log("Signature:", signedMessage.signature.toString());
    console.log("Serialized Message:", serializedMessage);
  }

  useEffect(() => {

    walletSolflare.on('connect', () => {

      console.log('connectedSOLFLARE', walletSolflare.publicKey.toString());
      setWalletAddress(walletSolflare.publicKey.toString());
      setSolflareWalletObject(walletSolflare);
      setWalletType("2");
      //signMsg();

      walletDivClose();

    });

   /* const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);*/

  }, [walletSolflare]);


  const renderNotConnectedContainer = () => (
    <button onClick={walletDivOpen}>Connect to Wallet</button>
  );

  const connectWallet = async () => {
    try {
      const { solana } = window;
      if (solana) {
        // Connect to the user's wallet and get their public key
        const response = await solana.connect();
        setWalletAddress(response.publicKey.toString());
        setwalletObject(response);

        checkIfWalletIsConnected();

        // Sign a message using the user's wallet
        const message = Uint8Array.from([...new TextEncoder().encode('MemeMon')]);
        const signedMessage = await solana.signMessage(message);

        // Check if the message property exists before converting it to an array
        let serializedMessage;
        if ('message' in signedMessage) {
          serializedMessage = Array.from(signedMessage.message);
        } else {
          serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
        }

        // Add the signature and serialized message to the response object
        response.signature = signedMessage.signature;
        response.serializedMessage = serializedMessage;

        console.log("Connected with public key:", response.publicKey.toString());
        console.log("Signature:", signedMessage.signature.toString());
        console.log("Serialized Message:", serializedMessage);

        setWalletType("1");
      }

      walletDivClose();

    } catch (err) {
      console.log(err);
    }
  };



  const getBalance = async () => {
    // Connect to cluster
    const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
    console.log(connection);

    // getBalance
    const myAddress = new PublicKey(walletAddress);
    const balance = await connection.getBalance(myAddress);
    setMywalletBalance(balance);

    console.log("From Wallet Address:", myAddress);
    console.log("Wallet Balance:", balance);
    console.log("LAMPORTS_PER_SOL:", LAMPORTS_PER_SOL);
  }

  async function closeNav() {
    set_navbarOpen(0);
    //this.setState({ _navbarOpen: 0 });
  }

  async function navbarOpen() {
    set_navbarOpen(1);
    //this.setState({ _navbarOpen: 0 });
  }

  async function presaleOpen() {
    set_presaleOpen(1);
    //this.setState({ _navbarOpen: 0 });
  }

  async function presaleClose() {
    set_walletDiv(0);
    set_presaleOpen(0);

    //this.setState({ _navbarOpen: 0 });
  }

  async function walletDivOpen() {
    set_walletDiv(1);
    //this.setState({ _navbarOpen: 0 });
  }

  async function walletDivClose() {
    set_walletDiv(0);
    //this.setState({ _navbarOpen: 0 });
  }


  return (
    <div class="allWrap">
      <div class="light">
        {_navbarOpen < 1 ?
          (<div class="miniBarMain">
            <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
          </div>) :
          (<div class="miniBarMain">
            <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
          </div>)}

        <div class="headers">

          <div class="h1">

            <div class="logoDiv"><img src={logoPic} /></div>

            <div class="right">

              <div class="connect">
                <div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
                <div ><Link activeClass="" id="fontSize" to="token" spy={true} smooth={true} duration={550}>$MMONOMIC</Link></div>
                <div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>

              </div>
              {!walletAddress ?
                (<button class="mintBtn" onClick={walletDivOpen}>CONNECT</button>) :
                (<button class="mintBtn" onClick={_disconnectWallet}>DISCONNECT</button>)
              }

            </div>

          </div>

        </div>

        <div class="cont">

          {_navbarOpen < 1 ?
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
            </div>) :
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
            </div>)}

          {_navbarOpen > 0 ?
            (<div class="littleNav">
              <div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
              <div ><Link activeClass="" id="fontSize" to="token" spy={true} smooth={true} duration={550}>$MMONOMIC</Link></div>
              <div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>

              <div><button class="mintBtn" onClick={walletDivOpen}>CONNECT</button></div>

            </div>) : null}
          <div class="vidCont">
            <video class="videoBg" src={video} autobuffer autoPlay loop muted playsInline></video>
          </div>
          <div class="introduction">
            <div className='introduction-2'>
              <div className='title'>Welcome to the wild and whimsical world of</div>
              <div className='mememon'>MeMeMon</div>
              <div className='fontSize2'>Where finance meets fun in the innovative landscape of Solana! </div>

              <div className='social'>
                {walletAddress ?
                  <div className='buy' onClick={presaleOpen}>Buy MeMeMon</div> :
                  <div className='buy' onClick={walletDivOpen}>Buy MeMeMon</div>
                }
                <div className='soocialDiv'><img onClick={teleG} src={tg} /></div>
                <div className='soocialDiv'><img onClick={xLink} src={x} /></div>
                <div className='soocialDiv'><img onClick={insta} src={instagram} /></div>
                <div className='soocialDiv'><img onClick={email} src={mail} /></div>
              </div>

              <div
                onClick={() => {
                  // Copy address to clipboard and handle notification
                  navigator.clipboard.writeText('4nmXysF2ptWvBbgYyZrLUB2eCZqcqgNJ5pkc8QApUMhJ')
                    .then(() => {
                      const notification = document.getElementById('copy-notification');
                      notification.classList.add('show');
                      setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
                    })
                    .catch(error => console.error('Failed to copy address:', error));

                  // Optional: Handle address selection
                }}
              >
                <div className="linkMain">
                  <input class="inputDiv" readOnly placeholder='4nmXysF2ptWvBbgYyZrLUB2eCZqcqgNJ5pkc8QApUMhJ' />

                  <img className="copy" src={copy} alt="Copy" />

                </div>

              </div>


            </div>

            <img className='introPic' src='https://gif2-c7y.pages.dev/gif/Transparent-GIF.gif' />

          </div>

        </div>

        <div><img className='imgStone' src={border} /></div>

        <Intro />

        <Element name="about">
          <About />
        </Element>

        <Element name="token">
          <div class="aboutMain--2">
            <div className='tokenMain'>
              <div class="conT">Presale $MMONOMIC</div>

              <div class="presale-container">
                <div className='tokenCon'>
                  Get ready to embark on a journey into the world of decentralized finance with MMON. As we prepare to launch our revolutionary token, we're offering you the exclusive opportunity to participate in our presale event.
                </div>

                <div>
                  <div className='solP'>Price 0.001 SOL</div>
                  <div class="input-container">
                    <input type="number" class="input-field" placeholder="Enter Amount" />
                  </div>
                  <button class="mint-btn">Mint</button>
                </div>
              </div>

            </div>
            <div className='gemDiv'>
              <img className='gem' src={gem} />
              <img className='gem2' src={gem2} />
            </div>
          </div>
        </Element>

        <Element name="team">
          <Team />
        </Element>

        <Footer />

        {_walletDiv > 0 ?
          <div class="formMain2">
            <form>
              <button onClick={presaleClose} class="closeNote">✖</button>
            </form>

            <div class="popUpDiv2">
              <div className='walletT'>Connect with </div>
              <div class="wallets">
                <div className='btnImg' onClick={connectWallet}>
                  <img src={phantom} alt="Phantom Wallet" />
                  <div>Phantom Wallet</div>
                </div>
                <div className='btnImg' onClick={solfareConnect}>
                  <img src={solflare} alt="Solflare Wallet" />
                  <div>Solflare Wallet</div>
                </div>
              </div>
            </div>

          </div> : null}

        {_presaleOpen > 0 ?
          <div class="formMain1">
            <form>
              <button onClick={presaleClose} class="closeNote">✖</button>
            </form>
            <div className="popUpDiv">
              <div class="aboutMain--2">
                <div className='tokenMain'>
                  <div class="conT"><span className='presaleT'>Presale $MMONOMIC</span></div>

                  {!walletAddress && renderNotConnectedContainer()}

                  <div class="presale-container">
                    <div className='tokenCon'>
                      Get ready to embark on a journey into the world of decentralized finance with MMON. As we prepare to launch our revolutionary token, we're offering you the exclusive opportunity to participate in our presale event.
                    </div>

                    {walletAddress ?

                      <div>

                        <div className='solP'>Price 0.00000267 SOL</div>
                        <div class="input-container">
                          <input name="TokenAmount" class="input-field" placeholder='Token Amount' value={_tokenAmount}
                            onChange={event => set_tokenAmount(event.target.value)}></input>

                        </div>

                        {_walletType === '1' ?
                          <button class="mint-btn" onClick={sendMoney}>Buy</button> :
                          <button class="mint-btn" onClick={checkSendMoneySolflare}>Buy</button>
                        }

                        <div className='note'>{response}</div>

                      </div>
                      :
                      <div>

                        <div className='solP'>Price 0.00000267 SOL</div>
                        <div class="input-container">
                          <input name="TokenAmount" class="input-field" placeholder='Token Amount' value={_tokenAmount}
                            onChange={event => set_tokenAmount(event.target.value)}></input>

                        </div>
                        <button class="mint-btnDis" onClick={sendMoney}>Buy</button>


                        <div className='note'>{response}</div>

                      </div>
                    }

                  </div>

                </div>
                <div className='gemDiv'>
                  <img className='gem' src={gem} />
                  <img className='gem2' src={gem2} />
                </div>
              </div>
            </div>
          </div> :
          null}
      </div >
    </div >
  )

}
export default Home;
